import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private http: HttpClient) {
	}

	api = "https://www.desiflicks.com/api";
	torrentLink = "magnet:?xt=urn:btih:223F7484D326AD8EFD3CF1E548DED524833CB77E&dn=Avengers.Endgame.2019.1080p.BRRip.x264-MP4&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.openbittorrent.com%3A6969%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2710%2Fannounce&tr=udp%3A%2F%2F9.rarbg.me%3A2780%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2730%2Fannounce&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=http%3A%2F%2Fp4p.arenabg.com%3A1337%2Fannounce&tr=udp%3A%2F%2Ftracker.torrent.eu.org%3A451%2Fannounce&tr=udp%3A%2F%2Ftracker.tiny-vps.com%3A6969%2Fannounce&tr=udp%3A%2F%2Fopen.stealth.si%3A80%2Fannounce";

	videoSource = "";
	infoHash: string;
	video: HTMLVideoElement

	startStream() {
		this.infoHash = this.torrentLink.split("btih:")[1].split("&")[0];
		this.http.get(this.api + 'api/add/' + this.infoHash).pipe(
			tap(() => {
				this.videoSource = this.api + "stream/" + this.infoHash + ".mp4";
				this.changeDetectorRef.markForCheck();
				this.video = document.querySelector("#video");
				this.video.src = this.videoSource;
				this.video.load();
				this.video.play();
			})
		).subscribe()
	}

	stopStream() {
		this.http.get(this.api + 'api/delete/' + this.infoHash).subscribe((data) => {
			this.infoHash = "";
			this.videoSource = "";
		})
	}
}
