<div class="thumbnail-container" [ngClass]="{'carousel-item': isCarouselItem ,'lobby-item': !isCarouselItem }">
	<a *ngIf="imageUrl" (click)="onClick()" class="thumbnail">
		<img class="title-image thumbnail" [src]="imageUrl+'w154'+title.poster_path" alt="{{title.title}}"
			onerror="this.src='./assets/images/defaultThumbnail.png';" />
	</a>
	<div class="title-info">
		<p>{{title.title}}</p>
		<p>{{title.mediaType}}</p>
		<span></span>
	</div>
	<div class="thumbnail thumb-placeholder"></div>
</div>