<!-- <div class="container">
	<h1>BrowserTorrent</h1>
	<div class="video" *ngIf="videoSource.length">
		<video width="640" height="360" autoplay preload controls type="video/mp4" src={{videoSource}}
			style="background:black"></video>
		<br>
		<button (click)="stopStream()" class="stop">Stop</button>
	</div>
	<div class="input">
		<p>Sample torrent: magnet:?xt=urn:btih:6a9759bffd5c0af65319979fb7832189f4f3c35d&amp;dn=sintel.mp4 (Sintel Open
			Source Movie, H.264 MP4, ~120 MiB). </p>
		<input [(ngModel)]="torrentLink" type="text" style="width:350px" name="torrent"
			placeholder="Enter torrent URL. " />
		<button (click)="startStream()" class="stream">Stream!</button>
	</div>
</div> -->
<router-outlet></router-outlet>