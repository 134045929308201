import { Category, MediaType } from './tmdb.consts';
import { IConfig, ITitleInfo } from './../lobby/title.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITitles } from '../lobby/title.interface';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TmdbService {

	baseUrl = "https://www.desiflicks.com/api";
	public tmdbConfig: IConfig;

	constructor(
		private http: HttpClient) {
	}

	getConfig(): Observable<IConfig> {
		return this.http.get<IConfig>(`${this.baseUrl}/config`);
	}

	getCategory(mediaType: MediaType, category: Category, numOfPages = 1): Observable<ITitles> {
		return this.http.get<ITitles>(`${this.baseUrl}/${mediaType}/${category}`, {
			params: {
				numOfPages: numOfPages.toString(),
				language: 'en-US'
			}
		});
	}

	// Search Movies
	searchMovies(searchQuery: string): Observable<ITitles> {
		const params = new HttpParams().set('query', searchQuery);
		return this.http.get<ITitles>(`${this.baseUrl}/search/movies`, { params });
	}

	// Search Shows
	searchShows(searchQuery: string): Observable<ITitles> {
		const params = new HttpParams().set('query', searchQuery);
		return this.http.get<ITitles>(`${this.baseUrl}/search/shows`, { params });
	}

	// Series Info
	seriesInfo(id: string): Observable<ITitleInfo> {
		return this.http.get<ITitleInfo>(`${this.baseUrl}/series/${id}`);
	}

	// Get Trending
	getTrending(mediaType: string): Observable<ITitles> {
		return this.http.get<ITitles>(`${this.baseUrl}/trending/${mediaType}`);
	}

	// Get By Country of Origin
	getByCountryOfOrigin(mediaType: string, countryCode: string = 'IN', pages: number = 1): Observable<ITitles[]> {
		const params = new HttpParams()
			.set('countryCode', countryCode)
			.set('pages', pages.toString());

		const results = this.http.get<ITitles[]>(`${this.baseUrl}/discover/${mediaType}`, { params });
		return results;
	}
}

