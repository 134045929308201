import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ThumbnailComponent } from './thumbnail.component';


@NgModule({
	declarations: [
		ThumbnailComponent,
	],
	imports: [
		MatIconModule,
		CommonModule
	],
	exports: [
		ThumbnailComponent
	],
	providers: [],
	bootstrap: [
		ThumbnailComponent
	]
})

export class ThumbnailModule { }