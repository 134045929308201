import { IConfig } from './../lobby/title.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITitle } from '../lobby/title.interface';
import { tap, debounceTime } from 'rxjs/operators';
import { TmdbService } from '../tmdb/tmdb.service';
import { MediaType } from '../tmdb/tmdb.consts';

@Component({
	selector: 'thumbnail',
	templateUrl: './thumbnail.component.html',
	styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent {

	imageUrl: string;
	imageLoaded = false;

	@Input() title: ITitle;
	@Input() isCarouselItem = true;
	@Input() config: IConfig;
	@Output() clickedThumbnail = new EventEmitter<ITitle>()

	public onClick() {
		this.clickedThumbnail.emit(this.title);
	}

	ngOnInit() {
		this.imageUrl = this.config.images.secure_base_url;
	}
}
