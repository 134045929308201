import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TitleInfoRoutingModule } from './title-info-routing.component';
import { TitleInfoComponent } from './title-info.component';

@NgModule({
	declarations: [
		TitleInfoComponent,
	],
	imports: [
		TitleInfoRoutingModule,
		CommonModule
	],
	exports: [
		TitleInfoComponent
	],
	providers: [],
	bootstrap: [TitleInfoComponent]
})
export class TitleInfoModule { }
