import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { take, tap, switchMap } from 'rxjs/operators';
import { TmdbService } from '../tmdb/tmdb.service';
import { ITitleInfo } from '../lobby/title.interface';
import { Subscription } from 'rxjs';
import { SearchService } from '../search/search.service';

@Component({
	selector: 'app-title-info',
	templateUrl: './title-info.component.html',
	styleUrls: ['./title-info.component.scss']
})
export class TitleInfoComponent implements OnInit {

	constructor(
		public route: ActivatedRoute,
		public tmdbService: TmdbService,
		public searchService: SearchService
	) { }

	currentTitleInfo: ITitleInfo;
	titleInfo$: Subscription;
	seasonList = [];


	ngOnInit(): void {
		this.route.queryParams.pipe(
			tap((params) => this.getTitleInfo(params.id)),
			take(1)
		).subscribe();
	}

	getTitleInfo(titleId: string) {
		this.titleInfo$ = this.tmdbService.seriesInfo(titleId).pipe(
			tap(title => {
				this.currentTitleInfo = title;
				this.seasonList = new Array(this.currentTitleInfo.number_of_seasons);
				this.searchTitle(1);
			}),
			take(1)
		).subscribe();
	}

	searchTitle(season: number) {
		const titleName = this.currentTitleInfo.original_name;
		this.searchService.searchTitle(200, `${titleName}.S0${season}`).pipe(
			switchMap(torrents => this.searchService.getEpisodes(torrents[0].info_hash, torrents[0].name).pipe(
				tap(episodes => console.log(episodes)),
			)),
			take(1)
		).subscribe()
	}

	ngOnDestroy() {
		this.titleInfo$.unsubscribe();
	}

}
