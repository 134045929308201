import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SearchService {

	constructor(
		private http: HttpClient) { }

	// api = "https://freeflix.herokuapp.com/";
	api = "https://www.desiflicks.com/api";

	headerDict = {

	};

	requestOptions = {
		headers: new HttpHeaders(this.headerDict),
	};

	searchTitle(category: number, title: string): Observable<any> {
		console.log(title)
		return this.http.get(this.api + `api/searchTitle/${category}/${title}`, this.requestOptions);
	}

	getEpisodes(hash: string, title: string): Observable<any> {
		return this.http.get(this.api + `api/getEpisodes/${hash}/${title}`, this.requestOptions);
	}
}
